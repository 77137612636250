import React, { useState } from "react";

import EQ from "../assets/images/svg/equalizer.svg";

const Video = ({ vimeoURL }) => {
  const [isMuted, setIsMuted] = useState(true);
  return (
    <div className="relative col-span-full bg-brand-dark">
      <video
        autoPlay
        loop
        muted={isMuted}
        playsInline
        className="w-full"
      >
        <source src={vimeoURL} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div
        className="absolute flex flex-row items-center cursor-pointer bottom-4 medium:bottom-8 tro-default-padding-x hover:opacity-80 transition-all duration-150 ease-out"
        onClick={() => {
          setIsMuted(!isMuted);
        }}
      >
        <EQ />
        <span className="pl-2 font-mono text-xs text-white uppercase medium:pl-3 medium:text-sm">
          Ton {isMuted ? "einschalten" : "ausschalten"}
        </span>
      </div>
    </div>
  );
};

export default Video;
